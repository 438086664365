.sv-components-row {
    flex-direction: column;
    .sv-components-container-left {
        width: 100%;
    }
    .sv-components-column--expandable {
        width: unset;

        height: calc(100vh - var(--header-height) - var(--comany-toolbar-height) - 74px);
        overflow: auto;

        .sd-body {
            &.sd-body--static {
                max-width: min(800px, calc(100vw - 360px - 260px - 68px));
                margin: 0;

                .sd-file__actions-container {
                    .sd-file__choose-btn {
                        border-radius: 8px;
                        border: 1px solid var(--border-color);
                        padding: 7px 16px;
                        box-shadow: 0px 1px 2px 0px #1018280D;
                        background-color: #FFFFFF;
                        color: var(--text-black);
                        font-family: Inter;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 20px;
                        text-align: left;

                        .sv-svg-icon {
                            fill: var(--text-black);
                        }
                    }
                }

                .sv-list__item.sv-list__item--selected>.sv-list__item-body {
                    color: var(--text-black);
                }

                .sd-file__decorator {
                    border-radius: 8px;
                    gap: 12px;
                    border-color: #DBDBDB;

                    .sv-string-viewer {
                        color: var(--text-grey);
                        font-family: Inter;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;

                    }
                }

                .sd-file {
                    padding-top: 18px;
                    margin-bottom: 17px;
                }

                .sd-file--readonly .sd-file__decorator {
                    border-color: #DBDBDB;
                }

                .sd-input.sd-dropdown:focus-within {
                    box-shadow: unset;
                }

                .sd-dropdown_clean-button {
                    display: none;
                }

                .sv-dropdown_select-wrapper use {
                    fill: var(--text-grey);
                }

                .sd-question--complex {
                    padding: 0;
                    overflow-x: hidden;
                    border: none;

                    .sd-element__header {
                        padding: 0;
                        padding-bottom: 24px;

                        &.sd-element__header--location-top:after {
                            content: none;
                        }
                    }

                    .sd-matrixdynamic__content {
                        padding: 0;
                        margin: 0;

                        .sd-table-wrapper {
                            margin: 0;
                            min-width: 100%;

                            &::before,
                            &::after {
                                content: none;
                            }

                            .sd-matrixdynamic {
                                display: flex;
                                flex-direction: column;

                                tbody {
                                    display: flex;
                                    flex-direction: column;
                                }

                                tr {
                                    display: flex;
                                    gap: 20px;
                                }

                                .sd-table__cell--header {
                                    text-align: left;
                                    border: 0;
                                    padding-bottom: 6px !important;

                                    .sv-string-viewer {
                                        font-family: Inter;
                                        font-size: 14px;
                                        font-weight: 500;
                                        line-height: 20px;
                                        text-align: left;
                                        color: #474747;
                                    }

                                    &.sd-table__cell--action {
                                        flex: 0 0 40px;
                                    }
                                }

                                .sd-table__cell {
                                    padding: 0;
                                    padding-bottom: 24px;
                                    border: 0;
                                    width: unset;
                                    min-width: unset;
                                    flex-grow: 1;
                                    flex-shrink: 1;
                                    flex-basis: 0;

                                    &:first-of-type {
                                        // padding-left: 0;
                                    }

                                    &:last-of-type {
                                        // padding-right: 0;
                                    }

                                    &.sd-table__cell--actions {
                                        // width: 10%;
                                        // padding-left: 0;
                                        flex: 0 0 auto;

                                        .sd-action-bar {
                                            margin-right: 0;
                                            justify-content: center;

                                            .sd-action__icon {
                                                scale: 0.8;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .sd-matrixdynamic__footer {
                            padding: 0;
                            // padding-bottom: 24px;

                            .sd-matrixdynamic__add-btn {
                                border-radius: 8px;
                                padding: 7px 16px;
                                background-color: #FFFFFF;
                                border: 1px solid var(--border-color);
                                box-shadow: 0px 1px 2px 0px #1018280D;
                                height: 34px;
                                width: 120px;
                                justify-content: center;
                                margin: 0;

                                .sv-string-viewer {
                                    font-family: Inter;
                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 20px;
                                    text-align: left;
                                    color: var(--text-black);
                                }

                            }
                        }
                    }
                }
            }
        }
    }
}

.sv_progress-toc {
    max-width: unset;

    &.sv_progress-toc--left {
        border-right: none;
    }

    &.sv_progress-toc--sticky {
        height: auto !important;
        padding: 0;
    }

    .sv-list__container {
        border-bottom: 1px solid #E9E9E9;

        .sv-list {
            display: flex;

            .sv-list__item {
                width: auto;
                height: 58px;
                display: flex;
                border-bottom: 2px solid transparent;

                .sv-list__item-body {
                    height: 100%;
                    align-content: center;
                }

                .sv-string-viewer {
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    text-align: left;
                    color: var(--text-grey);
                }

                &.sv-list__item--selected {
                    border-bottom: 2px solid var(--primary-color);

                    .sv-list__item-body {
                        background-color: unset;
                        border-radius: 0;
                        height: 100%;
                    }

                    .sv-string-viewer {
                        color: var(--text-black);

                    }

                }
            }

        }
    }
}

.sd-root--compact {
    .sd-body__navigation {
        .sv-action {
            flex: 1;
            border-radius: 8px;

            &#sv-nav-prev {
                .sv-action__content {
                    background-color: #F4F4F4 !important;

                    &:hover {
                        background-color: #F4F4F4;

                    }
                }
            }

            &#sv-nav-complete {
                display: none;
            }
        }

        :not(.sd-btn--action) {
            background-color: #111111 !important;
            border-radius: 8px;

            &:hover {
                background-color: #111111;

            }
        }

        .sd-btn {
            background-color: #111111;
            color: #FFFFFF;
            border: none;
            height: 44px;
            flex: 1;
            border-radius: 8px;
            padding: 0;
            box-sizing: border-box;
            cursor: pointer;
            font-family: Inter;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            text-align: center;
            box-shadow: 0px 1px 2px 0px #1018280D;

            &.sd-navigation__prev-btn {
                background-color: #F4F4F4 !important;
                color: var(--text-grey);
            }
        }
    }
}

.sd-root-modern.sd-root-modern--full-container {
    height: auto;
}

.sd-input {
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px 0px #1018280D;
    border-radius: 8px;
    padding: 10px 14px;
    color: #111111;
    background-color: var(--input-bg-color);
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;


    &::placeholder {
        color: var(--placeholder-color);
    }
}

.sd-input--readonly {
    background-color: #F9FAFB;
    color: #828282 !important;
    box-shadow: 0px 1px 2px 0px #1018280D !important;
    border: 1px solid #D0D5DD
}

.sd-input:focus {
    box-shadow: none;
}

.sd-question__header--location-top {
    padding-bottom: 6px;
}

.sd-element__title span {
    font-size: 14px;
    color: var(--text-black);
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
}

.sd-body.sd-body--static {
    padding: 0;
    max-width: 100%;

    .sd-body__page {
        padding: 54px 30px 0 30px;

        .sd-page__title {
            display: none;
        }

        .sd-page__row.sd-row--compact {
            margin: 0;
            margin-bottom: 34px;

            .sd-row {
                margin: 0;
            }

            .sd-checkbox {
                padding: 0;
                padding-top: 10px;


                .sd-checkbox__decorator {
                    height: 20px;
                    width: 20px;
                    border-radius: 6px;
                    border: 1px solid #DBDBDB;
                    margin-top: 2.5px;
                    box-shadow: none;
                }

                .sv-string-viewer {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 24px;
                    text-align: left;
                    color: var(--text-black);
                }

                &.sd-item--checked {
                    &.sd-checkbox--readonly {
                        .sd-checkbox__decorator {
                            background-color: #DBDBDB !important;
                        }
                    }

                    .sd-checkbox__decorator {
                        background-color: var(--active-color) !important;
                        border: none;

                        .sd-checkbox__svg {
                            use {
                                fill: #FFFFFF;
                            }
                        }
                    }
                }
            }

            .sd-question__comment-area {
                margin: 0;
                padding-top: 10px;
            }

            .sd-item--allowhover:not(.sd-item--readonly) .sd-selectbase__label:hover .sd-item__decorator {
                background: unset;
            }

            .sd-item__control:focus+.sd-item__decorator {
                box-shadow: none;
            }

            // margin-bottom: 24px;
            .sd-element--with-frame {
                border: none;
                padding: 0;

                .sd-panel__content {
                    padding: 0;
                }
            }
        }
    }

    .sd-element.sd-question {
        // margin-bottom: 24px;

        &.sd-question--readonly {
            .sd-comment {
                background-color: #F9FAFB;
                color: #828282 !important;
                box-shadow: 0px 1px 2px 0px #1018280D !important;
                border: 1px solid #D0D5DD
            }
        }

        .sd-element__header {
            .sd-description {
                margin-top: 6px;
                color: var(--text-grey);
                font-family: Inter;
                font-size: 14px;
                font-weight: 400;
                line-height: 16.94px;
                text-align: left;
            }
        }
        .progress-bar-container {
            min-width: 712px;
            height: 96px;
            background-color: white;
            border-radius: 8px;
            border: solid 1px #E9E9E9;
            padding: 16px;
            display: flex;
            box-sizing: border-box;
            margin-top: 38px;
            .container {
              display: flex;
              gap: 16px;
              width: 100%;
              .img-icon {
                display: flex;
                width: 40px;
                height: 40px;
                padding: 11.429px;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                border-radius: 20px;
                background: var(--Gray-100, #F4F4F4);
              }
              .progress-container-data {
                width: 100%;
                display: flex;
                gap: 4px;
                flex-direction: column;
                .progress-details {
                  display: flex;
                  justify-content: space-between;
                  .text-container {
                    display: flex;
                    flex-direction: column;
                    .file-name {
                      color: var(--Gray-700, #344054);
                      font-family: Inter;
                      font-size: 14px;
                      font-weight: 500;
                      line-height: 20px;
                    }
                    .file-size {
                      color: var(--Gray-500, #667085);
                      font-family: Inter;
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 20px;
                    }
                  }
                  .close-icon {
                    cursor: pointer;
                  }
                }
                .progress-container {
                  height: 20px;
                  align-items: center;
                  display: flex;
                  gap: 12px;
                  .show-bar {
                    height: 8px;
                    border-radius: 4px;
                    background: var(--Gray-100, #F4F4F4);
                    width: calc(100% - 12px);
                    .progress-bar-grow {
                      height: 8px;
                      border-radius: 4px;
                      background: var(--Green-100, #81BD2E);
                      display: block;
                    }
                  }
                  .sucess-msg {
                    color: var(--Green-100, #81BD2E);
                    font-family: Inter;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                  }
                }
              }
            }
          }

    }
}

.sd-error {
    background-color: var(--input-bg-color);
    color: var(--error-msg-color);
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    padding: 0;
    margin-top: 6px;
}

.sd-input--error {
    background-color: var(--input-bg-color);
    border: 1px solid var(--error-msg-color);
}