:root {
  --primary-color: #81BD2E;
  --secondary-color: #ffe058;
  --primary-light-color: #F4FAEA;
  --secondary-light-color: #f8ea60;
  --color4: #f1f1f1;
  --color5: #1f1f1f;
  --color6: #626262;
  --color7: #878787;
  --color8: #F4F4F4;
  --header-height: 80px;
  --active-color: #81BD2E;
  --active-bg-color: #F4FAEA;
  --error-msg-color: #FA5430;
  --placeholder-color: #828282;
  --input-bg-color: #FFFFFF;
  --border-color: #E9E9E9;
  --border-color1: #DBDBDB;
  --border-color2: #D0D5DD;
  --text-grey: #828282;
  --text-grey1: #646464;
  --text-grey2: #474747;
  --text-black: #111111;
  --grey-bg-color: #F4F4F4;
  --overlay-bg: #00000080;
  --hover-bg-color: #F9FAFB;
  --white: #FFFFFF;
  --light-grey-bg: #F7F9FA;
  --soft-grey-bg: #F1F3F5;
  --form-field-lable: #474747;
  --ace-green: #91D335;
}