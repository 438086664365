/* You can add global styles to this file, and also import other style files */
//@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@use '@angular/material' as mat;
@import '@angular/material/theming';
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@include mat.core();
@import './_colors';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

$my-typography: mat.define-typography-config($font-family: "'Inter'",
  );

$my-theme: mat.define-light-theme((typography: $my-typography,
    ));

@include mat.all-component-themes($my-theme);

$app-primary: mat-palette($custom-brand-primary);
$app-accent: mat-palette($custom-brand-accent);
$app-warn: mat-palette($custom-brand-warn);

$brand-light-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

@include angular-material-theme($brand-light-theme);

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Inter';
  background: #ffffff;
}

:focus-visible {
  outline: none
}

.title-class {
  font-size: 24px;
  font-weight: 700;
  padding: 2rem;
  text-transform: capitalize;
  margin: 0;
}

// snackbar style
.mdc-snackbar.mat-mdc-snack-bar-container {
  &.snack-bar-success {
    .mdc-snackbar__surface {
      background-color: var(--primary-color);
    }

    .mdc-snackbar__label {
      color: #ffffff;
    }
  }

  &.snack-bar-warn {
    .mdc-snackbar__surface {
      background-color: #ff4545;
    }

    .mdc-snackbar__label {
      color: #ffffff;
    }
  }

  .mdc-snackbar__surface {
    background-color: #FFFFFF;
    // box-shadow: none;
    padding: 0;
    margin-bottom: 1rem;
  }

  .mdc-snackbar__label {
    color: #111111;
    padding: 0;
  }
}

// angular material changes
.mat-drawer-backdrop.mat-drawer-shown {
  background-color: var(--overlay-bg) !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 12px !important;
}

.mat-drawer-container.mat-drawer-container-explicit-backdrop .mat-drawer-side {
  z-index: 0 !important;
}

.mdc-tab__ripple,
.mdc-line-ripple,
.mat-mdc-option .mat-mdc-option-ripple,
.mat-mdc-form-field .mat-mdc-form-field-focus-overlay,
.mat-mdc-form-field .mat-mdc-form-field-subscript-wrapper,
.mat-form-field-appearance-fill .mdc-text-field--no-label .mat-mdc-select-arrow-wrapper,
.mat-pseudo-checkbox.mat-mdc-option-pseudo-checkbox,
.mat-mdc-focus-indicator,
.mat-mdc-button-touch-target,
.mat-ripple.mat-mdc-button-ripple {
  display: none;
}

.mat-mdc-button-ripple,
.mdc-button__ripple {
  display: none;
}

.cdk-overlay-pane {
  width: auto !important;

  .mat-mdc-menu-panel {
    box-shadow: 0px 4px 6px -2px #1018280D, 0px 12px 16px -4px #1018281A, 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    max-width: unset;
  }

  .mat-mdc-select-panel {
    box-shadow: 0px 4px 6px -2px #1018280D, 0px 12px 16px -4px #1018281A, 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    border-radius: 8px !important;
    overflow-x: hidden;
    // margin: 4px !important;

    .mat-mdc-option {
      border-radius: 8px !important;

    }
  }
}

.cdk-overlay-dark-backdrop {
  background-color: #00000080;
}

.mat-mdc-select-panel {
  padding: 0 !important;
  width: auto !important;
}

.quarter-option.mat-mdc-option {
  height: 40px;
  box-sizing: border-box;
  width: 160px;
  padding: 10px 16px;
  min-height: 40px;
  background-color: #FFFFFF !important;
  margin: 0 4px;
  border-radius: 6px;

  &.mat-mdc-option-active {
    .option-text {
      color: var(--active-color);

      .active-icon {
        display: block;
      }

      .default-icon {
        display: none;
      }
    }
  }

  &:first-of-type {
    margin-top: 4px;
  }

  &:last-of-type {
    margin-bottom: 4px;
  }

  &:hover {
    background-color: var(--hover-bg-color) !important;
  }

  .option-text {
    display: flex;
    gap: 12px;
    align-items: center;
    color: #111111;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    letter-spacing: normal;

    .default-icon {
      display: block;
    }

    .active-icon {
      display: none;
    }
  }
}

.add-quarter-btn {
  border-top: 1px solid var(--border-color);
  border-radius: 0 !important;
  cursor: pointer;
  display: flex;
  gap: 12px;
  align-items: center;
  color: #111111;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  letter-spacing: normal;
  padding: 10px 16px;
  height: 40px;
  box-sizing: border-box;
  background-color: #FFFFFF;
  position: sticky;
  bottom: 0;
}

.mat-mdc-option {
  &.add-quarter-options {
    width: 352px;
    box-sizing: border-box;
  }
}

.company-action {
  transform: translate(7%, 7%);
}

.person-name-position {
  transform: translate(25%, -10%);
}

.portfolio-action {
  transform: translate(-8%, 0%);
}

// survey js form design  changes
@import './survey-js.scss';

// monite design  changes
@import './monite.scss';

// mat-menu style - tablet screen navigation menu
.custom-menu {
  background-color: #FFFFFF;

  .mat-mdc-menu-content {
    padding: 0;
  }

  .custom-menu-item {
    color: var(--text-black);
    padding: 5px 10px;
    cursor: pointer;
    height: 48px;
    width: 235px;
    padding: 12px 18px;
    box-sizing: border-box;
    border-radius: 6px;

    &.active-link {
      background-color: var(--active-bg-color);
      color: var(--active-color)
    }
  }
}

//dropdown arrow roatation
.arrow-down {
  transform: rotate(0deg);

  &.open {
    transform: rotate(180deg);
  }
}